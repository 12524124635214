import { Box, Typography, styled } from "@mui/material";
import CustomButton from "../../../Common/CustomButton";
import certificateImg from "../../../../images/certificateImg.png";
import { useRef } from "react";
import CertificatePreview from "./CertificatePreview";
import tublianLogo from "../../../../images/bg-tublian.svg";
import { useLearnContext } from "../LearnContext";

const CourseCertificate = () => {
  const { roadmapPhasesList, handleDownloadCertificate, user } =
    useLearnContext();
  const certificateRef = useRef(null);
  // console.log("roadmapPhasesList", roadmapPhasesList);

  return (
    <StyledContainer>
      <StyledContent>
        {roadmapPhasesList?.status && (
          <StyledEmojis>
            <CelebrationEmoji style={{ transform: "rotate(-0.25turn)" }}>
              🎉
            </CelebrationEmoji>
            <HeaderText>Congratulations!!</HeaderText>
            <CelebrationEmoji sx={{ fontSize: "64px" }}>🎉</CelebrationEmoji>
          </StyledEmojis>
        )}
        {!roadmapPhasesList?.status && (
          <HeaderText>You don’t have a certificate yet</HeaderText>
        )}
        <SubText>
          {roadmapPhasesList?.status
            ? "congratulations for finishing the course, your certificate is ready for download."
            : "your certificate will be ready for download when course is completed."}
        </SubText>
        {roadmapPhasesList?.status && (
          <CustomButton
            size="medium"
            style={{ marginTop: "22px" }}
            onClick={() =>
              handleDownloadCertificate(
                roadmapPhasesList.status,
                certificateRef
              )
            }
          >
            Download Certificate
          </CustomButton>
        )}
      </StyledContent>
      {roadmapPhasesList?.status && (
        <>
          <CertPreviewWrapper>
            <CertificatePreview
              name={user}
              certificateId={roadmapPhasesList.cert.id}
              date={roadmapPhasesList.cert.date}
              text={roadmapPhasesList.cert.text}
              certView="preview"
            />
          </CertPreviewWrapper>
          <Box sx={{ opacity: 0, height: 0, overflow: "hidden" }}>
            <CertDownloadWrapper ref={certificateRef}>
              <CertificatePreview
                name={user}
                certificateId={roadmapPhasesList.cert.id}
                date={roadmapPhasesList.cert.date}
                text={roadmapPhasesList.cert.text}
                certView="download"
              />
            </CertDownloadWrapper>
          </Box>
        </>
      )}
      {!roadmapPhasesList?.status && (
        <CertificateImage
          src={certificateImg}
          alt="Certificate for eighty twenty"
        />
      )}
    </StyledContainer>
  );
};

export default CourseCertificate;

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
}));

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  textAlign: "center",
  [theme.breakpoints.down(550)]: {
    marginTop: "0px",
  },
}));

const StyledEmojis = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
  height: "63px",
  [theme.breakpoints.down(550)]: {
    marginTop: "40px",
  },
}));

const CelebrationEmoji = styled(Typography)(({ theme }) => ({
  fontSize: "64px",
  [theme.breakpoints.down(550)]: {
    fontSize: "40px",
  },
}));

const HeaderText = styled(Typography)(({ theme }) => ({
  color: "#fefefe",
  fontSize: "36px",
  lineHeight: "52px",
  fontWeight: 700,
  // width: '549px',
  [theme.breakpoints.down(550)]: {
    width: "100%",
    fontSize: "20px",
    lineHeight: "32px",
  },
}));

const SubText = styled(Typography)(({ theme }) => ({
  color: "#B7B7B7",
  marginTop: "8px",
  fontSize: "18px",
  lineHeight: "30px",
  fontWeight: 400,
  width: "494px",
  [theme.breakpoints.down(550)]: {
    width: "100%",
    fontSize: "14px",
    lineHeight: "20px",
  },
}));

const CertificateImage = styled("img")(({ theme }) => ({
  marginTop: "40px",
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginTop: "20px",
  },
}));

const CertPreviewWrapper = styled(Box)(({ theme }) => ({
  marginTop: "40px",
  width: "891px",
  color: "black",
  paddingBlock: "30px",
  paddingInline: "32px",
  height: "100%",
  padding: "30px",
  position: "relative",
  backgroundColor: "#ffffff",
  backgroundImage: `url(${tublianLogo})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  [theme.breakpoints.down(1200)]: {
    width: "100%",
    marginTop: "20px",
  },
}));

const CertDownloadWrapper = styled(Box)(({ theme }) => ({
  width: "891px",
  height: "630px",
  padding: "0px",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#ffffff",
  backgroundImage: `url(${tublianLogo})`,
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
}));
