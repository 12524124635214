import { userProfile } from "./UserProfile";
import Bugsnag from "@bugsnag/js";

const ft_lambda_url = process.env.REACT_APP_COPILOT_API_BASE;

const userCache = userProfile(localStorage);

const copilotUrl = (path) => {
  return `${ft_lambda_url}${path}`;
};

const CopilotEndpoint = {
  async makeGptStreamingCall(uri, payload, onChunk, onFinished, onError) {
    try {
      const response = await fetch(copilotUrl(uri), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const decoder = new TextDecoder();
      const reader = response.body.getReader();
      let chunks = "";
      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          onFinished(chunks);
          break;
        }
        const result = decoder.decode(value);
        chunks += result;
        onChunk(chunks);
      }
    } catch (error) {
      Bugsnag.notify(error);
      onError(error);
    }
  },
  async buildLearningRoadmapStreaming(
    learningObjective,
    prompt,
    userId,
    onChunk,
    onFinished,
    onError
  ) {
    const payload = {
      userId: userId,
      objective: learningObjective,
      userPrompt: prompt,
    };
    CopilotEndpoint.makeGptStreamingCall(
      `/pathway/learning/streaming/roadmap`,
      payload,
      onChunk,
      onFinished,
      onError
    );
  },
  async buildLearningRoadmap(
    learningObjective,
    prompt,
    queryType = "user",
    userId
  ) {
    const payload = {
      userId: userId,
      objective: learningObjective,
      userPrompt: prompt,
      queryType,
    };
    const response = await fetch(
      copilotUrl(`/pathway/learning/background/roadmap`),
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  },
  async buildDeepdiveRoadmap(
    learningObjectives,
    learningPrompt,
    roadmapId,
    phaseId,
    sectionNumber
  ) {
    const payload = {
      userId: userCache.userId(),
      objectives: learningObjectives,
      userPrompt: userCache.profilePrompt(),
      parentRoadmapInfo: {
        prompt: learningPrompt,
        id: roadmapId,
        phaseId,
        sectionNumber,
      },
    };
    const response = await fetch(
      copilotUrl(`/pathway/learning/background/roadmap/godeeper`),
      {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.json();
    return data;
  },
  // async summarizeSofQuestion(payload) {
  //   const response = await fetch(copilotUrl(`/sof/question/summary`), {
  //     method: "POST",
  //     body: JSON.stringify(payload),
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const data = await response.json();
  //   return data;
  // },
  async summarizeGithubIssue(payload) {
    const response = await fetch(copilotUrl(`/github/issue/summary`), {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    return data;
  },
  async linkedinFileUpload(formData) {
    const response = await fetch(copilotUrl(`/linkedin/pdf/upload`), {
      method: "POST",
      body: formData,
    });
    const data = await response.json();
    return data;
  },

  async passionQuestions(data) {
    const response = await fetch(copilotUrl(`/interview/questions/passion`), {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json().catch((e) => { });
  },

  async bioQuestions(profilePrompt) {
    const response = await fetch(copilotUrl(`/interview/questions/bio`), {
      method: "POST",
      body: JSON.stringify({
        profilePrompt: profilePrompt,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json().catch((e) => { });
  },

  async personalityQuestions(profilePrompt) {
    const response = await fetch(
      copilotUrl(`/interview/questions/personality`),
      {
        method: "POST",
        body: JSON.stringify({
          profilePrompt: profilePrompt,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    return response.json().catch((e) => { });
  },

  async getChatBotReply(query, username, prompt, conversationId) {
    const response = await fetch(copilotUrl("/bot/ask"), {
      method: "POST",
      body: JSON.stringify({
        username,
        query,
        prompt,
        conversationId,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json().catch((e) => { });
  },

  async getChatBotHistory(username) {
    const response = await fetch(copilotUrl("/bot/history"), {
      method: "POST",
      body: JSON.stringify({
        username,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json().catch((e) => { });
  },

  async getTublibotExplorerResponse(messages) {
    // const url = "http://127.0.0.1:5000/tublibot";
    const url = copilotUrl("/tublibot");

    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({
        messages,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    return response.json().catch((e) => { });
  },

  async fetchAIQuestions(sectionDetails) {
    var response = await fetch(copilotUrl("/pathway/questions"), {
      method: "POST",
      // headers: getTokenizedHeaders(),
      body: JSON.stringify({
        ...sectionDetails,
        userPrompt: userCache.profilePrompt(),
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    return response.json().catch((e) => { });
  },

  // used during onboarding
  async generateBio(name, roles) {
    var body = {
      name: name,
      languages: Object.keys(roles)
        .filter((role) => roles[role])
        .join(","),
    };
    var response = await fetch(
      copilotUrl(`/developer/playground/generate/bio`),
      {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    const bio = await response.json();
    return bio;
  },
};

export default CopilotEndpoint;
