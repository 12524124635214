export const PATHWAY_ID = "os-internship-v3";


export const isInternshipCompleted = (progress: any) => {
  return progress && progress.status === true;
}
export const weekOneLearningObjectives = [
  "Learn what is OpenSource",
  "Why OpenSource is important for software industry",
  "Learn how beginners can contribute to OpenSource",
  "Bascis of Git & Github",
  "Make your first contribution",
];

export const weekTwoLearningObjectives = [
  "Prompt Engineering basics",
  "Generating Code with AI",
  "Understand the capabilities and limitations of AI in code generation.",
  "Tublian AI Copilot for Open Source",
  "TOS Developer Agent"
];

export const weekThreeLearningObjectives = [
  "Structure of Open Source Project",
  "Open source Communities",
  "Role of an Open Source Maintainer",
  "Work required to run and maintain Open Source"
];

export const weekFourLearningObjectives = [
  "Working effectively with legacy codebases",
  "Using TOS Agent to explore unknown and new codebases"
];

export const learningResourcesWeekOne = [
  {
    id: "week11",
    title: "What is Open Source?",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/9xpiecssKKw/upload/be6e02652e03a11ab61a8f3656de3c12.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/what-is-open-source",
    description:
      "Open source software is software with source code that anyone can inspect, modify, and enhance",
    status: "incomplete",
  },
  {
    id: "week12",
    title: "Why Contritute to Open Source?",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/JoRoy500nCc/upload/cdbec2f94b21646971fe7d78dd0a373e.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/open-source-for-emerging-developers",
    description: "Open source was once a bonus; now it's essential - The best way to become a successful software developer in 2023 and beyond and build an amazing career in tech is to 'Build in Public.'",
    status: "incomplete",
  },
  {
    id: "week13",
    title: "How to Contribute to Open Source?",
    thumbnail: "https://www.freecodecamp.org/news/content/images/size/w2000/2023/07/Open-Source-for-Developers-Book-Cover--1-.png",
    link: "https://www.freecodecamp.org/news/a-practical-guide-to-start-opensource-contributions/",
    description: "In the programming world, open source is a generic term for Open Source Software (OSS). Open-source software is built on source code that's open to everyone to view, change, extend, and distribute.",
    status: "incomplete",
  },
  {
    id: "week14",
    title: "Learning Git/Github",
    thumbnail:
      "https://www.digitalocean.com/_next/static/media/intro-to-cloud.d49bc5f7.jpeg",
    link: "https://www.digitalocean.com/community/cheatsheets/how-to-use-git-a-reference-guide",
    description: "Teams of developers and open-source software maintainers typically manage their projects through Git, a distributed version control system that supports collaboration. This cheat sheet style guide provides a quick reference to commands that are useful for working and collaborating in a Git repository.",
    status: "incomplete",
  },
  {
    id: "week15",
    title: "How to create a Pull Request on Github",
    thumbnail:
      "https://www.digitalocean.com/_next/static/media/intro-to-cloud.d49bc5f7.jpeg",
    link: "https://www.digitalocean.com/community/tutorials/how-to-create-a-pull-request-on-github",
    description:
      "Open-source projects that are hosted in public repositories benefit from contributions made by the broader developer community through pull requests, which request that a project accept changes you have made to its code repository",
    status: "incomplete",
  },
  {
    id: "week17",
    title: "A Checklist for Crafting an Effective Pull Request",
    thumbnail: "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/iupXZ62DQBY/upload/645bf0518c9f0b49da524f3f3bdeb794.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/a-checklist-for-crafting-an-effective-pull-request",
    description:
      "In the world of open-source development, Pull Requests (PRs) are pivotal. However, many new contributors, including myself, initially struggled with the 'right' way to create them. There isn't a one-size-fits-all approach, but there are best practices to follow. This guide aims to simplify this process for you.",
    status: "incomplete",
  },
];

export const learningResourcesWeekTwo = [
  {
    id: "week21",
    title: "AI-assisted coding",
    thumbnail:
      "https://d3lkc3n5th01x7.cloudfront.net/wp-content/uploads/2023/12/04031617/AI-assisted-coding.png",
    link: "https://www.leewayhertz.com/ai-assisted-coding/",
    description:
      "Tools, Types, working mechanism, benefits, and future trends: AI-assisted coding represents a groundbreaking approach to software development, utilizing advanced AI algorithms and machine learning techniques to augment the capabilities of developers in writing, testing, and debugging code",
    status: "incomplete",
  },
  {
    id: "week22",
    title: "What are the benefits and limitations of using AI code generators in programming?",
    thumbnail:
      "https://miro.medium.com/v2/resize:fit:1400/format:webp/1*wRlt5g3iW9eHoG70dBX2Og.png",
    link: "https://medium.com/@FxisAi/what-are-the-benefits-and-limitations-of-using-ai-code-generators-in-programming-4871283f8cbb",
    description:
      "Exploring the Pros and Cons of AI Code Generators in Programming: In recent years, artificial intelligence (AI) has made significant strides in the field of software development, particularly with the emergence of AI code generators.",
    status: "incomplete",
  },
  {
    id: "week23",
    title: "Tublian AI Copilot for Open Source",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/vkfrFrAIO4o/upload/a1bd2a7765ed3e93cb3d148651cd8789.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/ai-copilot-for-open-source-contributors",
    description: "Use Tublian AI Copilot for Open Source to discover, analyze, and contribute",
    status: "incomplete",
  },
  {
    id: "week24",
    title: "AI and the Future of Software Engineering",
    thumbnail:
      "https://i.ytimg.com/vi/DZQFmOWP1y4/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=DZQFmOWP1y4",
    description: "As AI becomes more accessible and more engineers try it, many of the early outlandish claims and fears have subsided. And while there’s little concern now that AI will replace software engineers any time soon, there are many questions regarding where and how to best use AI when writing, delivering, and operating software. In this panel session, we’ll speak with engineering leaders and AI experts about how they’re currently using AI and what skills and processes we need to develop to succeed in our AI-augmented future.",
    status: "incomplete",
  },
];

export const learningResourcesWeekThree = [
  {
    id: "week31",
    title: "Role of a Maintainer",
    thumbnail:
      "https://www.fosslife.org/sites/default/files/styles/wide_background/public/2021-06/2021_06_23_maintainer_image-by-wilfried-pohnke-from-pixabay_1600x1000.png?h=2aa300aa&itok=Hw3Bf7Aw",
    link: "https://www.fosslife.org/whats-open-source-software-maintainer",
    description:
      "The role of open source software maintainer is a difficult and often thankless one. A recent Tidelift survey, for example, found that 46 percent of open source project maintainers aren't even paid. Nonetheless, the work maintainers do is critical to ensure the long-term usability, security, and reliability of open source software",
    status: "incomplete",
  },
  {
    id: "week32",
    title: "Work behind Open Source",
    thumbnail:
      "https://cdn.hashnode.com/res/hashnode/image/stock/unsplash/CyPHeY3qQds/upload/2c4597955cff46e4859f34bf8f89368e.jpeg?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    link: "https://devlog.tublian.com/understanding-the-work-behind-open-source-a-guide-for-new-contributors",
    description:
      "The role of open source software maintainer is a difficult and often thankless one. A recent Tidelift survey, for example, found that 46 percent of open source project maintainers aren't even paid. Nonetheless, the work maintainers do is critical to ensure the long-term usability, security, and reliability of open source software",
    status: "incomplete",
  },
];

export const learningResourcesWeekFour = [
  {
    id: "week41",
    title: "Learning a New & unfamiliar codebase",
    resourceType: "video",
    thumbnail: "https://i.ytimg.com/vi/MlGAU_Gv2cA/maxresdefault.jpg",
    link: "https://www.youtube.com/watch?v=MlGAU_Gv2cA",
    description:
      "Learn a few ways to jump into a new code base quickly without feeling intimidated.",
    status: "incomplete",
  },
  {
    id: "week42",
    title: "How to Work With a Large Legacy Codebase Like a Pro",
    thumbnail:
      "https://www.freecodecamp.org/news/content/images/size/w2000/2023/03/david-rangel-4m7gmLNr3M0-unsplash.jpg",
    link: "https://www.freecodecamp.org/news/how-to-work-with-a-large-legacy-codebase/",
    description:
      "The legacy codebase you're working on is the result of the decisions made by the business, engineering leaders, and developers in your company. And that means you have to be careful while dealing with it because it is called 'legacy codebase' for those reasons.",
    status: "incomplete",
  },
];

export const weeklyPathwayData = [
  {
    week: 1,
    title: "Open Source fundamentals",
    header: `This week, we will learn the basics of Open source & make our first contribution.`,
    learningResources: learningResourcesWeekOne,
    category: "first contribution",
    aim: "Make your first contribution",

    description:
      "Time to make your first Open source contribution. Pick one of the issues, follow the guidelines, and contribute.",
    learningObjectives: weekOneLearningObjectives,
    theme: "First contribution",
    eightyTwentyPrompt: 'I am new to Open Souce software development. Based on my interest areas build a roadmap that focuses on basics of git,github, making affective contributions to open source projects'
  },
  {
    week: 2,
    title: "AI as a Software Development Catalyst",
    header: "This phase focuses on leveraging AI to enhance software development processes. It covers practical applications of AI in coding, including prompt engineering and the use of tools like GitHub Copilot, to streamline and innovate development workflows.",
    learningResources: learningResourcesWeekTwo,
    category: "documentation fix",
    aim: "Use Tublian AI tools",
    description: "There are many ways to contribute to an Open source project. Pick the task you like.",
    learningObjectives: weekTwoLearningObjectives,
    theme: "AI for software development",
    eightyTwentyPrompt: 'Using AI as a catalyst for software development. Covers all the topics below - Prompt Engineering - Techniques and best practices for designing effective prompts for AI. - Using Github Copilot as a coding assistance. - Generating code using AI. - How AI is changing software development.',
  },
  {
    week: 3,
    title: "Open Source Communities",
    header: "This week, we will focus on the Open source community, how these projects are structured, and how you should pick your Open source projects.",
    learningResources: learningResourcesWeekThree,
    category: "triage",
    aim: "Become a helpful member of the Open Source Community",
    description: "Help triage and resolve issues. Pick the task you like.",
    learningObjectives: weekThreeLearningObjectives,
    theme: "Resolve Issues",
    eightyTwentyPrompt: 'I’m looking to gain a deep understanding of Open Source. I want to explore how Open Source projects are structured, the responsibilities and challenges of an Open Source Maintainer, the ongoing work involved in running and maintaining these projects, and how Open Source communities are organized and operate.',
  },
  {
    week: 4,
    title: "Working with large codebases",
    header: "Working with legacy codebase. How to contribute to an existing Open Source project",
    learningResources: learningResourcesWeekFour,
    category: "code changes",
    aim: "Open Source contribution using TOS Agent",
    description: "Fix or add new features. Pick the task you like.",
    learningObjectives: weekFourLearningObjectives,
    theme: "Make changes",
    eightyTwentyPrompt: 'I’m looking to dive deep into working with large legacy codebases. I want to understand the challenges of maintaining and improving legacy systems, best practices for navigating and refactoring complex, older code, how to manage technical debt, and the tools and strategies that can help make working with legacy codebases more efficient.',
  },
];

export const pathWayTopics = [
  "internship-projects",
  "hacktoberfest",
  "backend",
  "frontend",
  "library",
  "framework",
  "ai",
  "python",
  "flutter",
  "Java",
  "dotnet",
  "c",
  "rust",
  "php",
];
