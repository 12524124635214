import {
  FC,
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import { IGitHubIssueForList } from "../../../Projects/types";
import { userProfile } from "../../../../libs/UserProfile";
import ProfileEndpoint from "../../../../libs/ProfileEndpoint";
import { logEvent } from "../../../../libs/amplitude";
import { GithubIssue } from "../../../../models/github-issue";
import CopilotEndpoint from "../../../../libs/CopilotEndpoint";
import LearningRoadmapEndpoint from "../../../../libs/LearningRoadmapEndpoint";
import {
  AiQuestions,
  Course,
  ErrorType,
  FilterOptions,
  ILearningProgress,
  LearnContextType,
  LoadingState,
  LoadingStateUpdate,
  Phase,
  PopupStateData,
  RecentlyInteractedRoadmap,
  RoadmapList,
  RoadmapListItem,
} from "../../Common/LearningTypes";
import { pathWayTopics } from "../../../AiOsInternshipPathway/AiOsPathwayData";
import FT from "../../../../libs/FT";
import { save8020Event } from "../../../Common/Analytics/UserEventsAPI";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const LearnContext = createContext<LearnContextType | undefined>(undefined);

export function useLearnContext() {
  const context = useContext(LearnContext);
  if (!context) {
    throw new Error("useLearnContext must be used within a LearnProvider");
  }
  return context;
}

export const LearnProvider: FC = ({ children }) => {
  const userCache = userProfile(localStorage);
  const history = useHistory();

  const [hasAccessToInternship, setHasAccessToInternship] = useState(false);
  const [cohort, setCohort] = useState("");

  const [roadmapLimit, setRoadmapLimit] = useState(3);
  const [activeSectionId, setActiveSectionId] = useState<number>(1);
  const [activeCourse, setActiveCourse] = useState<Course>({
    courseName: "",
    description: "",
    stacks: [],
    id: "",
    phaseRoadmapId: "",
    structure: [],
    courseTopics: [],
    sections: new Map([]),
  });
  const [roadmapList, setRoadmapList] = useState<RoadmapList | []>([]);
  const [activeRoadmapTab, setActiveRoadmapTab] = useState<1 | 2>(2);

  const [roadmapPhasesList, setRoadmapPhasesList] = useState<
    RoadmapListItem | undefined
  >();

  const [deepdiveRoadmapPhases, setDeepdiveRoadmapPhases] = useState<
    RoadmapListItem | undefined
  >();

  const [recentInteractedRoadmaps, setRecentInteractedRoadmaps] = useState<
    RecentlyInteractedRoadmap[] | undefined
  >();
  const [popupStateData, setPopupStateData] = useState<PopupStateData>({
    isActive: false,
    roadmap: undefined,
    course: undefined,
    activeContent: undefined,
    feedbackData: {
      reaction: undefined,
      // feedback: {}
      feedbackMsg: "",
    },
    toastData: {
      duration: 6000, // in Ïmiliseconds
      title: "",
      subtitle: "",
      actionMessage: "",
      action: undefined,
    },
    paymentStatusData: {
      status: undefined,
      message: "",
    },
    popupMode: "paymentStatus",
  });

  const updatePopupStateData = (
    key: keyof PopupStateData,
    newValue: PopupStateData[keyof PopupStateData]
  ) => {
    setPopupStateData((prevData) => ({ ...prevData, [key]: newValue }));
  };

  const [error, setError] = useState<ErrorType>({
    error: false,
    errorMsg: "",
  });
  const [credits, setCredits] = useState<number>(0);
  const [aiQuestions, setAiQuestions] = useState<AiQuestions>();
  const [searchResults, setSearchResults] = useState<
    IGitHubIssueForList[] | GithubIssue[]
  >([]);
  const [learnQuery, setLearnQuery] = useState("");
  const [learningProgress, setLearningProgress] = useState<
    ILearningProgress | undefined
  >(undefined);
  const [learningTaskProgress, setLearningTaskProgress] = useState<{
    [key: string]: {
      status: boolean;
      completedTasks: any[];
      openTasks: any[];
      date: any;
    };
  }>({
    section1: { status: false, completedTasks: [], openTasks: [], date: {} },
    section2: { status: false, completedTasks: [], openTasks: [], date: {} },
    section3: { status: false, completedTasks: [], openTasks: [], date: {} },
    section4: { status: false, completedTasks: [], openTasks: [], date: {} },
  });
  const [user, setUser] = useState("");

  function updateAiQuestions(
    courseId: string,
    sectionId: number,
    questions: string[]
  ) {
    setAiQuestions((prevState) => {
      // Check if courseId exists in the state
      if (!prevState || !prevState[courseId]) {
        // Create new entry for courseId and sectionId
        return {
          ...prevState,
          [courseId]: {
            [sectionId]: questions,
          },
        };
      }

      // Check if sectionId exists under courseId
      if (!prevState[courseId][sectionId]) {
        // Create new entry for sectionId and assign questions to it
        return {
          ...prevState,
          [courseId]: {
            ...prevState[courseId],
            [sectionId]: questions,
          },
        };
      }

      // Update the array of questions for sectionId
      return {
        ...prevState,
        [courseId]: {
          ...prevState[courseId],
          [sectionId]: questions,
        },
      };
    });
  }

  const [loadingState, setLoadingState] = useState<LoadingState>({
    searchStatus: "not_searched",
    loadingAiQuestions: "not_loaded",
    creatingRoadmaps: false,
    creatingDeepdiveRoadmap: false,
    loadingDeepdiveRoadmap: "not_loaded",
    loadingRoadmaps: false,
    loadingCredits: false,
    loadedCredits: false,
    fetchingLearningContent: false,
    fetchingMoreLearningContent: false,
    deletingRoadmap: false,
  });

  const getRoadmapPhaseByCourseId = (
    roadmapData: RoadmapListItem,
    courseId: string
  ): Phase | undefined =>
    roadmapData?.roadmap?.phases?.find((p) => p.id === courseId);

  const updateLoadingState = (
    propertyName: keyof LoadingState,
    newValue: LoadingStateUpdate[keyof LoadingState]
  ) => {
    setLoadingState((prevState) => ({
      ...prevState,
      [propertyName]: newValue,
    }));
  };

  const fetchAiQuestions = async (course: Course) => {
    if (course?.sections?.get(activeSectionId)) {
      updateLoadingState("loadingAiQuestions", "loading");
      const { title, description, learningObjectives } =
        course?.sections?.get(activeSectionId);

      const sectionDetails = {
        title,
        header: description,
        learningObjectives,
      };

      const res: {
        questions: string[] | { difficulty: string; question: string }[];
        reasons: string[];
        summary: string;
      } = await CopilotEndpoint.fetchAIQuestions(sectionDetails);
      // console.log("q", res);

      // res?.questions &&
      //   updateAiQuestions(course.id, activeSectionId, res.questions);

      if (
        Array.isArray(res.questions) &&
        typeof res.questions[0] === "string"
      ) {
        updateAiQuestions(
          course.id,
          activeSectionId,
          res.questions as string[]
        );
      } else if (
        Array.isArray(res.questions) &&
        typeof res.questions[0] === "object"
      ) {
        const questionArray = (
          res.questions as { difficulty: string; question: string }[]
        ).map(
          (item: { difficulty: string; question: string }) => item.question
        );

        updateAiQuestions(course.id, activeSectionId, questionArray);
      }

      updateLoadingState("loadingAiQuestions", "loaded");
    }
  };

  const handleSearch = useCallback(
    async (category: string, isPublic: boolean, courseTopics: string[]) => {
      // console.log("searching");

      // setSearchStatus("searching");
      updateLoadingState("searchStatus", "searching");
      logEvent("LearnAndBuild", isPublic ? "public" : userCache.userId(), {
        sourceComponent: "search",
        category,
      });

      const response = await ProfileEndpoint.getIssuesByCategories(
        userCache.userId(),
        category,
        pathWayTopics,
        courseTopics //course topics are languages
      );

      // console.log("🚀 ~ response:", response);

      setSearchResults(response.issues);
      // setSearchStatus("searched");
      updateLoadingState("searchStatus", "searched");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const updateCourseTaskProgress = async (
    questionResponse: any,
    course: Course,
    roadmapId: string
  ) => {
    const updatedLearningProgress = { ...learningProgress?.progress };
    updatedLearningProgress[activeSectionId]?.openTasks?.push(questionResponse);
    // eslint-disable-next-line
    const res = await ProfileEndpoint.updatePathwayTaskProgress(
      course.id, // pathwayId
      undefined, // status
      updatedLearningProgress, // progress
      roadmapId,
      "course" //pathwayType
    );

    //Update progress
    if (res) {
      const updatedLP = await ProfileEndpoint.getOrCreateCourseProgress(
        course.id,
        activeSectionId,
        course?.sections?.get(activeSectionId)?.learningObjectives,
        undefined,
        roadmapId
      );

      updatedLP && setLearningProgress(updatedLP.result);
    }
  };

  const fetchLearningProgress = async (
    course: Course,
    phaseContentFilters: FilterOptions | undefined,
    loader: any = (value: boolean) =>
      updateLoadingState("fetchingLearningContent", value),
    roadmapId?: string
  ) => {
    loader(true);
    try {
      const response = await ProfileEndpoint.getOrCreateCourseProgress(
        course.id,
        activeSectionId,
        course?.sections?.get(activeSectionId)?.learningObjectives,
        phaseContentFilters,
        roadmapId
      );
      // console.log(">>>> course progress ", response);
      if (response?.error) throw new Error("Failed to fetch progress");
      setLearningProgress(response.result);
    } catch (error) {
      console.error("Error fetching/updating pathway progress:", error);
    } finally {
      loader(false);
    }
  };

  const fetchRoadmapList = async () => {
    updateLoadingState("loadingRoadmaps", true);

    const roadmapList = await LearningRoadmapEndpoint.listRoadmaps();
    // console.log("🚀 ~ fetchRoadmapList ~ roadmapList:", roadmapList);
    setRoadmapList(roadmapList);
    updateLoadingState("loadingRoadmaps", false);
  };

  const createSamplePrompts = () => {
    const sampleQueries = [
      "I know DevOps but I want to learn MLOps",
      "I am a reactjs developer. Now teach me AngularJS",
      "I want to learn Rust",
    ];

    const selectedLangs = userProfile(localStorage).selectedLanguages();
    // console.log("🚀 ~ createSamplePrompts ~ selectedLangs:", selectedLangs);
    const queries = [];
    if (selectedLangs?.length >= 2) {
      queries.push(
        `I want to prepare for a technical interview focusing on ${selectedLangs[0]} and ${selectedLangs[1]}.`
      );
    }
    if (selectedLangs?.length > 2) {
      queries.push(`I want to upgrade my skills on ${selectedLangs[2]}`);
    }
    if (selectedLangs?.length > 3) {
      queries.push(`I know ${selectedLangs[3]}, and now I want to become a Rust Developer.
      `);
    }
    return queries.concat(sampleQueries).slice(0, 3);
  };

  // const getCreditStatus = async (
  //   loader = (val: boolean) => updateLoadingState("loadingCredits", val)
  // ) => {
  //   // updateLoadingState("loadingCredits", true);
  //   loader(true);

  //   const credits = await LearningRoadmapEndpoint.getCredits();
  //   setCredits(credits);
  //   updateLoadingState("loadedCredits", true);
  //   loader(false);
  // };

  // const updateCreditStatus = async (newCredit: number) => {
  //   updateLoadingState("loadingCredits", true);

  //   const { credits } = await LearningRoadmapEndpoint.updateCredits(newCredit);
  //   // console.log("🚀 ~ updateCreditStatus ~ credits:", credits);

  //   setCredits(credits);
  //   updateLoadingState("loadingCredits", false);
  // };

  function updateRoadmap(
    arr: RoadmapList,
    id: string,
    updatedData: RoadmapListItem
  ): RoadmapList {
    return arr.map((item) =>
      item.roadmapId === id ? { ...item, ...updatedData } : item
    );
  }

  const triggerSearchRoadmaps = async (
    query = learnQuery,
    queryType = "user"
  ) => {
    // updateCreditStatus(5);
    // console.log("lq", query);

    if (!(query?.length > 0)) {
      setError({
        error: true,
        errorMsg: "Learning objective not found",
      });
      return;
    }

    updateLoadingState("creatingRoadmaps", true);
    // await getCreditStatus(); // Make sure creditStatus is current

    // if (credits <= 0) {
    //   setError({
    //     error: true,
    //     errorMsg: "Insufficient credits, please top up!",
    //   });
    //   updateLoadingState("creatingRoadmaps", false);
    // } else {
    //   const newRoadmap: RoadmapListItem = {
    //     roadmapId: "noIdYet",
    //     userId: userCache.userId(),
    //     updatedAt: Date.now(),
    //     roadmapState: "incomplete",
    //     status: false,
    //     createdAt: Date.now(),
    //     roadmap: {
    //       id: "noIdYet",
    //       queryType,
    //       internalLearningPrompt: "",
    //       learningPrompt: query,
    //       phases: [],
    //     },
    //   };

    //   setRoadmapList((prevRoadmapList) => [newRoadmap, ...prevRoadmapList]);
    //   setRoadmapPhasesList(newRoadmap); // Update the single roadmap page

    //   // eslint-disable-next-line
    //   const res = await LearningRoadmapEndpoint.createRoadmap(query, queryType);
    //   // console.log("🚀 ~ res:", res);

    //   const { message, status, roadmapId } = res;

    //   if (status?.toLowerCase() === "error" || !roadmapId) {
    //     if (message?.toLowerCase() === "inappropriate query") {
    //       setError({
    //         error: true,
    //         errorMsg:
    //           "Inappropriate query: we are sorry but we cannot handle your query, please try another.",
    //       });

    //       updateLoadingState("creatingRoadmaps", false);
    //       return;
    //     }
    //     setError({
    //       error: true,
    //       errorMsg: "Error encountered! Please try again",
    //     });

    //     updateLoadingState("creatingRoadmaps", false);
    //     return;
    //   }

    //   history.push(`/learn/${userCache.userId()}/${roadmapId}`);

    //   setRoadmapList((prevRoadmapList) =>
    //     updateRoadmap(prevRoadmapList, "noIdYet", { ...newRoadmap, roadmapId })
    //   );

    //   setRoadmapPhasesList((prev) => ({
    //     ...newRoadmap,
    //     ...prev,
    //     roadmapId,
    //   })); // Update the single roadmap page

    //   const checkRoadmapState = async () => {
    //     let currentRoadmap: RoadmapListItem =
    //       await LearningRoadmapEndpoint.getRoadmap(
    //         roadmapId,
    //         userCache.userId()
    //       );
    //     // console.log("🚀 ~ checkRoadmapState ~ currentRoadmap:", currentRoadmap);

    //     setRoadmapList((prevRoadmapList) =>
    //       updateRoadmap(prevRoadmapList, roadmapId, currentRoadmap)
    //     );

    //     setRoadmapPhasesList(currentRoadmap); // Update the single roadmap page

    //     if (currentRoadmap.roadmapState === "failure") {
    //       setError({
    //         error: true,
    //         errorMsg: "Error encountered, please try again",
    //       });

    //       updateLoadingState("creatingRoadmaps", false);
    //       return;
    //     }

    //     if (currentRoadmap.roadmapState !== "success") {
    //       setTimeout(checkRoadmapState, 5000); // Reduce number of API calls
    //     } else {
    //       setLearnQuery("");
    //       updateLoadingState("creatingRoadmaps", false);
    //     }

    //     await save8020Event(
    //       currentRoadmap.roadmapId,
    //       currentRoadmap?.roadmap.learningPrompt,
    //       currentRoadmap?.roadmap?.image
    //     );
    //   };

    //   await checkRoadmapState();
    // }

    const newRoadmap: RoadmapListItem = {
      roadmapId: "noIdYet",
      userId: userCache.userId(),
      updatedAt: Date.now(),
      roadmapState: "incomplete",
      status: false,
      createdAt: Date.now(),
      cert: {
        date: "",
        id: "noIdYet",
        text: "",
      },
      roadmap: {
        id: "noIdYet",
        queryType,
        internalLearningPrompt: "",
        learningPrompt: query,
        phases: [],
      },
    };

    setRoadmapList((prevRoadmapList) => [newRoadmap, ...prevRoadmapList]);
    setRoadmapPhasesList(newRoadmap); // Update the single roadmap page

    // eslint-disable-next-line
    const res = await LearningRoadmapEndpoint.createRoadmap(query, queryType);
    // console.log("🚀 ~ res:", res);

    const { message, status, roadmapId } = res;

    if (status?.toLowerCase() === "error" || !roadmapId) {
      if (message?.toLowerCase() === "inappropriate query") {
        setError({
          error: true,
          errorMsg:
            "Inappropriate query: we are sorry but we cannot handle your query, please try another.",
        });

        updateLoadingState("creatingRoadmaps", false);
        return;
      }
      setError({
        error: true,
        errorMsg: "Error encountered! Please try again",
      });

      updateLoadingState("creatingRoadmaps", false);
      return;
    }

    history.push(`/learn/${userCache.userId()}/${roadmapId}`);

    setRoadmapList((prevRoadmapList) =>
      updateRoadmap(prevRoadmapList, "noIdYet", { ...newRoadmap, roadmapId })
    );

    setRoadmapPhasesList((prev) => ({
      ...newRoadmap,
      ...prev,
      roadmapId,
    })); // Update the single roadmap page

    const checkRoadmapState = async () => {
      let currentRoadmap: RoadmapListItem =
        await LearningRoadmapEndpoint.getRoadmap(roadmapId, userCache.userId());
      // console.log("🚀 ~ checkRoadmapState ~ currentRoadmap:", currentRoadmap);

      setRoadmapList((prevRoadmapList) =>
        updateRoadmap(prevRoadmapList, roadmapId, currentRoadmap)
      );

      setRoadmapPhasesList(currentRoadmap); // Update the single roadmap page

      if (currentRoadmap.roadmapState === "failure") {
        setError({
          error: true,
          errorMsg: "Error encountered, please try again",
        });

        updateLoadingState("creatingRoadmaps", false);
        return;
      }

      if (currentRoadmap.roadmapState !== "success") {
        setTimeout(checkRoadmapState, 5000); // Reduce number of API calls
      } else {
        setLearnQuery("");
        updateLoadingState("creatingRoadmaps", false);
      }

      await save8020Event(
        currentRoadmap.roadmapId,
        currentRoadmap?.roadmap.learningPrompt,
        currentRoadmap?.roadmap?.image
      );
    };

    await checkRoadmapState();
  };

  const createDeepdiveRoadmap = async (
    learningPrompt: string,
    roadmapId: string
  ) => {
    updateLoadingState("creatingDeepdiveRoadmap", true);
    // await getCreditStatus(); // Make sure creditStatus is current

    // if (credits <= 0) {
    //   setError({
    //     error: true,
    //     errorMsg: "Insufficient credits, please top up!",
    //   });
    //   updateLoadingState("creatingDeepdiveRoadmap", false);
    // } else {
    const newRoadmap: RoadmapListItem = {
      userId: userCache.userId(),
      roadmapId: "noIdYet",
      updatedAt: Date.now(),
      roadmapState: "incomplete",
      status: false,
      createdAt: Date.now(),
      cert: {
        date: "",
        id: "noIdYet",
        text: "",
      },
      roadmap: {
        id: "noIdYet",
        queryType: "ai",
        internalLearningPrompt: "",
        learningPrompt,
        phases: [],
      },
    };

    setDeepdiveRoadmapPhases(newRoadmap);

    const payload = {
      learningObjectives:
        activeCourse?.sections?.get(activeSectionId)?.learningObjectives,
      learningPrompt,
      roadmapId,
      phaseId: activeCourse.id,
      sectionNumber: activeSectionId - 1,
    };

    // console.log("🚀 ~ payload:", payload);

    // eslint-disable-next-line
    const res: {
      message: string;
      roadmapId: string;
      status: string;
    } = await LearningRoadmapEndpoint.createDeepdiveRoadmap(payload);

    // console.log("🚀 ~ res:", res);

    const { message, status } = res;
    const deepdiveRoadmapId = res.roadmapId;

    if (status?.toLowerCase() === "error" || !deepdiveRoadmapId) {
      if (message?.toLowerCase() === "inappropriate query") {
        setError({
          error: true,
          errorMsg:
            "Inappropriate query: we are sorry but we cannot handle your query, please try another.",
        });

        updateLoadingState("creatingDeepdiveRoadmap", false);
        return;
      }
      setError({
        error: true,
        errorMsg: "Error encountered! Please try again",
      });

      updateLoadingState("creatingDeepdiveRoadmap", false);
      return;
    }

    setDeepdiveRoadmapPhases((prev) => ({
      ...newRoadmap,
      ...prev,
      roadmapId: deepdiveRoadmapId,
    })); // Update the single roadmap page

    const checkRoadmapState = async () => {
      let currentRoadmap: RoadmapListItem =
        await LearningRoadmapEndpoint.getRoadmap(
          deepdiveRoadmapId,
          userCache.userId()
        );
      // console.log("🚀 ~ checkRoadmapState ~ currentRoadmap:", currentRoadmap);

      setDeepdiveRoadmapPhases(currentRoadmap);

      if (currentRoadmap.roadmapState === "failure") {
        setError({
          error: true,
          errorMsg: "Error encountered, please try again",
        });

        updateLoadingState("creatingDeepdiveRoadmap", false);
        return;
      }

      if (currentRoadmap.roadmapState !== "success") {
        setTimeout(checkRoadmapState, 5000); // Reduce number of API calls
      } else {
        setLearnQuery("");

        // Updating the active course to contain the deepdive id
        const section = activeCourse.sections.get(activeSectionId);
        if (section) {
          section.goDeeperRoadmapId = deepdiveRoadmapId;
          setActiveCourse((prevActiveCourse) => ({
            ...prevActiveCourse,
            sections: new Map(prevActiveCourse.sections).set(
              activeSectionId,
              section
            ),
          }));
        }

        updateLoadingState("creatingDeepdiveRoadmap", false);
      }
    };

    await checkRoadmapState();
    // }
  };

  const handleGetRoadmap = async (
    roadmapId: string,
    userId = userCache.userId() // Default to current user's ID
  ) => {
    updateLoadingState("creatingRoadmaps", true);

    const checkRoadmapState = async () => {
      let currentRoadmap: RoadmapListItem =
        await LearningRoadmapEndpoint.getRoadmap(roadmapId, userId);

      // console.log("🚀 ~ checkRoadmapState ~ currentRoadmap:", currentRoadmap);
      setRoadmapList((prevRoadmapList) =>
        updateRoadmap(prevRoadmapList, roadmapId, currentRoadmap)
      );

      setRoadmapPhasesList(currentRoadmap); // Update the single roadmap page

      if (currentRoadmap.roadmapState === "failure") {
        setError({
          error: true,
          errorMsg: "Error encountered, please try again",
        });

        updateLoadingState("creatingRoadmaps", false);
        return;
      }

      if (currentRoadmap.roadmapState !== "success") {
        setTimeout(checkRoadmapState, 5000); // Reduce number of API calls
      } else {
        updateLoadingState("creatingRoadmaps", false);
      }
    };

    await checkRoadmapState();
  };

  const handleDeleteRoadmap = async (
    roadmapId: string,
    // returnCredit = true
    returnCredit = false
  ) => {
    // console.log("deleting roadmap");
    // Optimistically remove the roadmap from the list
    const updatedRoadmapList = roadmapList.filter(
      (roadmap) => roadmap.roadmapId !== roadmapId
    );
    setRoadmapList(updatedRoadmapList);

    // updateLoadingState("deletingRoadmap", true);

    try {
      const { status } = await ProfileEndpoint.deleteRoadmap(
        roadmapId,
        returnCredit
      );
      // console.log("🚀 ~ status:", status);

      if (status) {
        // Assume roadmapList is up to date now so don't fetch
        // await Promise.all([fetchRoadmapList(), getCreditStatus()]);

        setPopupStateData((prev) => ({
          ...prev,
          toastData: {
            duration: 6000, // in Ïmiliseconds
            title: "Roadmap Deleted!",
            subtitle: "Your roadmap was deleted successfully",
            // actionMessage: "",
            // action: undefined,
          },
          popupMode: "toast",
        }));

        // Update credit if we returned credit
        // if (returnCredit) await getCreditStatus();
      } else {
        setError({ error: true, errorMsg: "Error: Roadmap deletion failed" });
      }
    } catch (err) {
      setRoadmapList(roadmapList); // Set back to previous value
      // updateLoadingState("deletingRoadmap", false);
      setError({ error: true, errorMsg: "Error: Roadmap deletion failed" });
    } finally {
      // updateLoadingState("deletingRoadmap", false);
    }
  };

  const fetchInteractedRoadmapPhases = async (
    roadmapId: string,
    loader?: (status: "not_loaded" | "loading" | "loaded") => void,
    switchTab = false
  ) => {
    try {
      loader && loader("loading");
      setRecentInteractedRoadmaps(undefined);

      const res = await ProfileEndpoint.getRecentUserRoadmap(
        userProfile(localStorage).userId(),
        roadmapId
      );
      // console.log("🚀 ~ fetchInteractedRoadmaps ~ res:", res);

      if (res?.error) throw new Error(`Error: ${res.error}`);

      res && switchTab && setActiveRoadmapTab(1);
      res && setRecentInteractedRoadmaps([res]);
    } catch (error) {
      console.error("error", error);
    } finally {
      loader && loader("loaded");
    }
  };

  function getPhaseProgress(recentPathway: ILearningProgress | undefined) {
    const progress = recentPathway?.progress;
    if (!progress) return { completed: 0, total: 3 };
    const keys = Object.keys(progress).filter((key) =>
      ["1", "2", "3", "4"].includes(key)
    );
    const completed = keys.filter((key) => progress[key]?.status)?.length || 0;
    return { completed, total: keys?.length || 3 };
  }

  const handleDownloadCertificate = async (
    status: boolean,
    certificateRef: React.MutableRefObject<null>
  ) => {
    if (status) {
      const certificateElement = (certificateRef.current ||
        document.body) as HTMLElement;
      html2canvas(certificateElement, {
        scale: 3,
        useCORS: true,
        scrollX: 0,
        scrollY: 0,
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("landscape", "mm", "a4");
        const imgWidth = 297;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        const padding = 10;
        const pdfWidth = imgWidth - 2 * padding;
        const pdfHeight = imgHeight - 2 * padding;
        pdf.addImage(imgData, "PNG", padding, padding, pdfWidth, pdfHeight);
        pdf.save(`${user}.pdf`);
      });
    }
  };

  const getUser = async () => {
    const response = await FT.get(userCache.userId(), {});
    if (response.name) {
      // console.log("respnse", response);
      setUser(response.name);
    }
  };

  const contextValue: LearnContextType = {
    hasAccessToInternship,
    cohort,
    roadmapLimit,
    activeSectionId,
    activeCourse,
    roadmapList,
    deepdiveRoadmapPhases,
    roadmapPhasesList,
    activeRoadmapTab,
    popupStateData,
    aiQuestions,
    searchResults,
    loadingState,
    learningProgress,
    learningTaskProgress,
    learnQuery,
    credits,
    error,
    recentInteractedRoadmaps,
    user,
    setUser,
    setHasAccessToInternship,
    setCohort,
    setRoadmapLimit,
    setActiveSectionId,
    setActiveCourse,
    setRoadmapList,
    setDeepdiveRoadmapPhases,
    setRoadmapPhasesList,
    setActiveRoadmapTab,
    setPopupStateData,
    updatePopupStateData,
    setAiQuestions,
    setSearchResults,
    updateAiQuestions,
    updateLoadingState,
    setLearningProgress,
    setLearningTaskProgress,
    handleSearch,
    fetchAiQuestions,
    fetchLearningProgress,
    updateCourseTaskProgress,
    setLearnQuery,
    fetchRoadmapList,
    // getCreditStatus,
    // updateCreditStatus,
    setCredits,
    triggerSearchRoadmaps,
    createDeepdiveRoadmap,
    handleGetRoadmap,
    handleDeleteRoadmap,
    setError,
    getRoadmapPhaseByCourseId,
    createSamplePrompts,
    setRecentInteractedRoadmaps,
    fetchInteractedRoadmapPhases,
    getPhaseProgress,
    handleDownloadCertificate,
  };

  useEffect(() => {
    const checkAccessToInternship = async () => {
      try {
        // console.log("Checking internship access");
        const response = await FT.getAuthorizedUser();
        // console.log("🚀 ~ checkAccessToInternship ~ response:", response);

        if (response && response.hasAccessToInternship) {
          setHasAccessToInternship(response.hasAccessToInternship);
        }
        if (response && response.cohort) {
          setCohort(response.cohort);
          userCache.setCohort(response.cohort);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    checkAccessToInternship();
    getUser();
    // eslint-disable-next-line
  }, []);

  return (
    <LearnContext.Provider value={contextValue}>
      {children}
    </LearnContext.Provider>
  );
};
