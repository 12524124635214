import { Typography, Box, styled } from "@mui/material";
import logo from "../../../../images/tublianCertLogo.svg";
import nilSignature from "../../../../images/nilSignature.svg";
import certBadge from "../../../../images/cert-badge.svg";

function CertificatePreview({
  name,
  certificateId,
  date,
  certView,
  text,
}: CertificatPreviewProps) {
  return (
    <StyledCertificateContainer certView={certView}>
      <Box>
        <StyledLogoContainer>
          <img src={logo} alt="Tublian logo" />
        </StyledLogoContainer>
        <StyledCertificateTitle variant="h3" certView={certView}>
          CERTIFICATE
        </StyledCertificateTitle>
        <StyledSubTitle variant="h5" certView={certView}>
          OF COMPLETION
        </StyledSubTitle>
        <StyledRecipientName variant="h4" certView={certView}>
          {name}
        </StyledRecipientName>
        <StyledDivider certView={certView} />
        <StyledDescription certView={certView}>{text}</StyledDescription>
      </Box>
      <StyledFooter certView={certView}>
        <StyledSignature>
          <Box>
            <img
              src={nilSignature}
              alt="signature"
              style={{ objectFit: "cover" }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "12.52px",
              fontWeight: 800,
              fontFamily: "Open Sans",
              lineHeight: "17.05px",
            }}
          >
            Nilanjan Raychaudhuri
          </Typography>
          <Typography
            sx={{
              fontSize: "10.42px",
              fontWeight: 700,
              fontFamily: "Open Sans",
              lineHeight: "14.19px",
            }}
          >
            Founder, Tublian
          </Typography>
        </StyledSignature>
        <Box
          sx={{
            display: "flex",
            gap: "4px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledCertBadge />
          <Box>
            <StyledCertInfo>CERTIFIED</StyledCertInfo>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                fontWeight: 800,
              }}
            >
              {certificateId}
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Open Sans",
                fontWeight: 800,
              }}
            >
              {date}
            </Typography>
          </Box>
        </Box>
      </StyledFooter>
    </StyledCertificateContainer>
  );
}

export default CertificatePreview;

type CertificatPreviewProps = {
  name?: string;
  certificateId?: string;
  date: any;
  text: string;
  certView?: "preview" | "download";
};

interface StyledCertificateContainerProps {
  certView?: "preview" | "download";
}

// Styled Components
const StyledCertificateContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  borderColor: "#FD642E",
  borderWidth: "3px",
  borderStyle: "solid",
  borderRadius: "24px",
  height: "100%",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  [theme.breakpoints.down(1200)]: {
    width: certView === "preview" ? "100%" : "",
  },
}));

const StyledLogoContainer = styled(Box)({
  marginLeft: "-10px",
});

const StyledCertificateTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  fontWeight: 700,
  color: "black",
  fontFamily: "Inter",
  fontSize: "48.08px",
  textAlign: "left",
  marginTop: "25px",
  lineHeight: "54.56px",
  [theme.breakpoints.down(550)]: {
    fontSize: certView === "preview" ? "24px" : "",
    lineHeight: certView === "preview" ? "24.56px" : "",
  },
}));

const StyledSubTitle = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  color: "#FC3946",
  fontWeight: 500,
  marginBottom: "40px",
  textAlign: "left",
  fontFamily: "Poppins",
  fontSize: "22.54px",
  lineHeight: "38.81px",
  letterSpacing: "7.5px",
  [theme.breakpoints.down(550)]: {
    fontSize: certView === "preview" ? "14.08px" : "",
    letterSpacing: certView === "preview" ? "3px" : "",
    lineHeight: certView === "preview" ? "16px" : "",
  },
}));

const StyledRecipientName = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  textAlign: "center",
  marginTop: "40px",
  fontFamily: "Poppins",
  textTransform: "capitalize",
  fontSize: "40px",
  [theme.breakpoints.down(550)]: {
    fontSize: certView === "preview" ? "20.08px" : "",
  },
}));

const StyledDivider = styled(Box, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  borderBottom: "2px solid #FD642E",
  marginBottom: "20px",
  width: certView === "preview" ? "85%" : "700px",
  [theme.breakpoints.down(550)]: {
    width: certView === "preview" ? "100%" : "",
  },
}));

const StyledDescription = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  color: "black",
  fontSize: "15.77px",
  lineHeight: "23.65px",
  marginBottom: "10px",
  textAlign: "left",
  fontFamily: "Poppins",
  fontWeight: 500,
  width: certView === "preview" ? "85%" : "700px",
  [theme.breakpoints.down(550)]: {
    width: certView === "preview" ? "100%" : "",
    fontSize: certView === "preview" ? "14.08px" : "",
    lineHeight: certView === "preview" ? "20px" : "",
  },
}));

const StyledFooter = styled(Box, {
  shouldForwardProp: (prop) => prop !== "certView",
})<StyledCertificateContainerProps>(({ theme, certView }) => ({
  display: "flex",
  justifyContent: "end",
  gap: "140px",
  marginTop: "46px",
  alignItems: "flex-end",
  [theme.breakpoints.down(550)]: {
    width: certView === "preview" ? "100%" : "",
    gap: certView === "preview" ? "20px" : "",
    flexDirection: certView === "preview" ? "column" : "",
    alignItems: certView === "preview" ? "flex-start" : "",
  },
}));

const StyledSignature = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontFamily: "Open Sans",
});

const StyledCertBadge = styled(Box)({
  width: "35.75px",
  height: "46.5px",
  backgroundImage: `url(${certBadge})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});

const StyledCertInfo = styled(Typography)(({ theme }) => ({
  textTransform: "uppercase",
  fontSize: "14px",
  fontFamily: "Poppins",
  fontWeight: 700,
  color: "#FD642E",
}));
